<template>
  <div>
    <Row class="p-b-10 p-t-20  workplatform-title">
      <i-col span="14">
        <span>付款项 </span>
        <Button v-show="defaultItems.length" type="primary" size="small" icon="md-redo"  @click="resetPayItems">采用右侧推荐付款项</Button>
      </i-col>
      <i-col span="10">
        <strong>推荐支付方式</strong>
      </i-col>
    </Row>
    <Row class="p-b-10" :gutter="16">
      <i-col span="14">
        <ol>
          <li class="p-b-10" v-for="(item,i) in payments" :key="i">
            于 <DatePicker size="small" placeholder="选择付款日期" v-model="item.date" style="width: 120px"/> 之前，
            支付乙方：
            <Input size="small" prefix="logo-yen" number v-model="item.money" @on-focus="currentPaymoney = item.money" @on-blur="handleItemChanged(item)" placeholder="输入支付金额" style="width: 120px" /> 元
            <a class="m-l-10 " @click="paymentItemSettingMark(item)">标注</a>
            <a class="m-l-5 " style="color:#ef4f4f;" @click="paymentItemRemove(item,i)">删除</a>
            <template v-if="item.mark && item.mark.length>0">
              <p class="remark p-t-5">注：{{item.mark}}</p>
            </template>
          </li>
        </ol>
        总金额<span class="p-l-5 text-16" style="color:#44bd32;">{{formatMoney(totalMoney)}}</span>
      </i-col>
      <i-col span="10" v-show="defaultItems.length">
        <p>分档期支付费用如下：</p>
        <Timeline>
          <TimelineItem v-for="(item,i) in defaultItems" :key="i" color="blue">
            <h4>
              {{formatDate (item.date)}}
              支付：<span style="color:#44bd32;">{{formatMoney(item.money)}}</span>
            </h4>
            <p> 发布费: {{formatMoney(item.signNormalPrice)}}， 服务费: {{formatMoney(item.signServicePrice)}}</p>
          </TimelineItem>
        </Timeline>
        <p> 总发布费: {{formatMoney(total.signNormalPrice)}}， 总服务费: {{formatMoney(total.serviceFee)}}</p>
        合计支付：<span style="color:#44bd32;">{{formatMoney(totalMoney)}}</span>
      </i-col>
    </Row>

    <Modal v-model="modalPaymentMark" title="设置付款标注" width="400" @on-ok="updatePaymentItemMark">
        <Input
          clearable
          v-model="inpuPatmentMark"
          placeholder="请输入付款标注"
        />
    </Modal>

  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'

export default {
  props: {
    totalMoney: {
      type: Number,
      required: true,
      default: 0
    },
    payItems: {
      type: Array
    },
    defaultItems: {
      type: Array
    }
  },
  data () {
    return {
      payments: [],
      currentPaymoney: 0,
      modalPaymentMark: false,
      choosePaymentItem: {},
      inpuPatmentMark: '' // 输入的付款标注
    }
  },
  methods: {
    paymentItemRemove (payment, index) {
      if (this.payments.length > 1) {
        this.payments.splice(index, 1)
        const tmp = payment.money * 1
        this.payments[this.payments.length - 1].money = this.payments[this.payments.length - 1].money * 1 + tmp
        this.$emit('paymentUpdate', this.payments)
      } else {
        this.$Notice.error({
          desc: '至少要有一个付款项,已经为您重置了默认付款项'
        })
        setTimeout(() => {
          this.resetPayItems()
        }, 500)
      }
    },
    paymentItemSettingMark (payment) {
      this.modalPaymentMark = true
      this.choosePaymentItem = payment

      this.inpuPatmentMark = payment.mark
    },
    updatePaymentItemMark () {
      this.choosePaymentItem.mark = this.inpuPatmentMark

      this.$emit('paymentUpdate', this.payments)
    },
    paymentItemAdd () {
      const payed = this.computePayed()
      const length = this.payments.length
      const residuum = this.totalMoney - payed
      if (residuum > 0) {
        this.payments.push({
          date: this.payments[this.payments.length - 1].date,
          index: length,
          money: residuum.toFixed(2),
          mark: ''
        })
      }
      this.$emit('paymentUpdate', this.payments)
    },
    computePayed () {
      let payed = 0.00
      this.payments.forEach(item => {
        payed += Number(item.money)
      })
      return payed
    },
    handleItemChanged (item) {
      const payed = this.computePayed()
      const residuum = this.totalMoney - payed
      if (residuum < 0) {
        this.$Notice.error({
          desc: '您所配置的付款额已超出总金额:' + Math.abs(residuum.toFixed(2)) + '元,已经为您还原价格'
        })
        // 还原到最低价
        item.money = this.currentPaymoney
      } else {
        // item.money = item.money.toFixed(2)
        this.paymentItemAdd()
      }
    },
    resetPayItems () {
      const payMap = new Map()
      this.defaultItems.forEach(item => {
        const payItem = payMap.get(item.date) || { money: 0 }
        payItem.money = (Number(payItem.money) + Number(item.money)).toFixed(2)
        payItem.date = item.date
        payMap.set(item.date, payItem)
      })
      this.payments = [...payMap.values()]
      this.$emit('paymentUpdate', this.payments)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatDate (date) {
      return ParseDate(date)
    },
    initPayitems () {
      if (this.payItems.length > 0) {
        // this.payItems.forEach(element => {
        //   element.money = element.money.toFixed(2)
        // })
        this.payments = this.payItems
      } else {
        this.payments = this.defaultItems.map(x => Object.assign({}, x))
      }
    }
  },
  watch: {
    payItems: function () {
      this.payments = this.payItems
      this.handleItemChanged()
    },
    defaultItems: function () {
      if (!this.payItems.length) {
        this.resetPayItems()
      } else {
        const payed = this.computePayed()
        if (this.totalMoney !== payed) {
          this.resetPayItems()
        }
      }
    }
  },
  created () {
    this.initPayitems()
    this.$emit('paymentUpdate', this.payments)
  },
  computed: {
    total: function () {
      const total = { signNormalPrice: 0, serviceFee: 0 }
      this.defaultItems.forEach(item => {
        total.signNormalPrice += item.signNormalPrice
        total.serviceFee += item.serviceFee
      })
      return total
    }
  }
}
</script>
